import Common from "common/services/Common";
import { get, isEmpty, round, toNumber } from "lodash";
import browserHistory from "utils/browserHistory";
import createAction, { closeLoader, openLoader } from "utils/createAction";
import { getBuySideQualification } from "Qualification/QualificationActions";
import { DEBUG_BUYSIDE, ENV_NAME } from "common/constants/AppGlobals";
import { openGlobalErrorModalAction } from "data/globalErrorModal/actionCreators";
import { setRedirectTabAction } from "data/landing/actions";
import { setLockedProgramData } from "data/origin/actions";
import { rateSelectorBuySide } from "mocks/Buyside";
import { PATH_LOGIN, PATH_SEARCH_RESULTS } from "routes/constants";
import { getClientSettingsData } from "services/ClientSettingsServices";
import Session, {
  ALL_SUMMARY_PARAMS,
  ORIGIN_SOURCE,
  USER_TYPE,
} from "services/Session";
import {
  getEncompassSettings,
  rateSelector,
} from "services/lockSummaryServices";
import { truncateText, updatePollTransaction } from "utils/helpers";
import {
  SELECT_PRICE,
  selectProduct,
  setClientSettingsDataAction,
  setCompareDataAction,
  setCurrentRateSelectorAction,
  setDiffElementsAction,
  setRateSelectorAction,
  setSettings,
  setStatusFail,
} from "./actions";
import {
  checkPricingStatus,
  findBestProgram,
  getAdjustmentCompare,
  getSnapshotProgramInfo,
  parseOriginAdjustments,
  parseRateAdjustmentsValidate,
} from "./helpers";

export const getRateSelector = async (
  dispatch,
  loanData,
  buysideAdjustments
) => {
  const user = Session.get(USER_TYPE);
  const results = await rateSelector(dispatch, loanData, buysideAdjustments);

  if (results && results.code !== undefined) {
    if (user && user.userType === "StandAlone") {
      browserHistory.push(PATH_LOGIN);
    } else if (
      results.errors &&
      results.errors.find((o) => o.code === "PS031") &&
      results.summary &&
      results.summary.includes("EPPS90010")
    ) {
      const summaryResponse = JSON.parse(results.summary);
      dispatch(
        openGlobalErrorModalAction({
          errorMessage: summaryResponse.summary,
          onCloseCallback: () => {
            dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
          },
        })
      );
    } else if (
      results.errors &&
      results.errors.find((o) => o.code === "PS095")
    ) {
      dispatch(
        openGlobalErrorModalAction({
          errorMessage:
            "Your ICE PPE session has expired. Please close ICE PPE and log back in.",
          onCloseCallback: Common.closeApplication,
        })
      );
    } else {
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: Common.closeApplication,
        })
      );
    }
  }
  return results;
};

export const getRateSelectorAction = () => async (dispatch, state) => {
  dispatch(openLoader("Loading"));
  try {
    const loanData = get(state(), "EPPS.loanInfo") || {};
    // Sometimes, loanInfo.loanId isn't populated, so we have to find it from other objects.
    const loanId =
      get(state(), "EPPS.loanInfo.loanInformation.loanId") ||
      get(state(), "epps.lockSummary.loanId");
    loanData.loanInformation = loanData.loanInformation || {};
    loanData.loanInformation.loanId = loanId;
    const buysideAdjustments =
      get(state(), "epps.origin.buySideAdjustments") || {};
    const results = await getRateSelector(
      dispatch,
      loanData,
      buysideAdjustments
    );
    dispatch(setRateSelectorAction(results));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const getClientSettingSelector = () => async (dispatch) => {
  dispatch(openLoader("Loading"));
  try {
    const clientSettingsData = await getClientSettingsData();
    dispatch(setClientSettingsDataAction(clientSettingsData));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const getValidateDataAction = () => async (dispatch, state) => {
  const loanData = get(state(), "EPPS.loanInfo") || {};
  const originalLoanInfo = get(state(), "EPPS.originalLoanInformation") || {};
  const { relockRequest, additionalLockInfo } = loanData;
  const { specificRateDataID, specificProgramID } = relockRequest;
  const { lockRequests } = originalLoanInfo;
  const snapshotProgramName = getSnapshotProgramInfo(lockRequests);
  const loanFieldComparisson = Object.values(
    additionalLockInfo?.lockCompareResults || {}
  ).flat();
  const rateChange = loanFieldComparisson.find(({ id }) => id === "rate");
  const { proposed: proposedRate } = rateChange || {};
  const changedNoteRate = !!proposedRate;
  try {
    dispatch(openLoader("Loading"));
    const pricing = await getBuySideQualification(
      "historical",
      loanData,
      dispatch
    );
    const {
      program: selectedProgram,
      rateDataID: newRateDataID,
      status,
    } = checkPricingStatus(
      specificProgramID,
      specificRateDataID,
      proposedRate,
      pricing,
      changedNoteRate
    );
    dispatch(
      setLockedProgramData({
        programName: selectedProgram?.program || snapshotProgramName,
        programID: specificProgramID,
        notes: selectedProgram?.notes?.replace(/\{.*?\}/g, "") || "",
        status,
        modalOpen: true,
      })
    );

    if (status === "eligible") {
      const newLoanData = {
        ...loanData,
        relockRequest: { ...relockRequest, specificRateDataID: newRateDataID },
      };
      const summary = DEBUG_BUYSIDE
        ? rateSelectorBuySide
        : await getRateSelector(dispatch, newLoanData);
      dispatch(setRateSelectorAction(summary));

      if (!DEBUG_BUYSIDE) {
        dispatch(selectProduct(selectedProgram));
      }
      if (
        loanFieldComparisson.length > 0 ||
        newRateDataID !== specificRateDataID
      ) {
        const originAdjustments = parseOriginAdjustments(originalLoanInfo);
        const adjustmentsToAdd = originAdjustments.filter(
          (adj) =>
            adj.adjustmentType === "ReLockFeeAdjustment" ||
            adj.adjustmentType === "CustomPriceAdjustment" ||
            adj.adjustmentType === "CorporatePriceConcession" ||
            adj.adjustmentType === "BranchPriceConcession"
        );
        const proposedAdjustments = summary.lien.adjustments.concat(
          adjustmentsToAdd
        );
        const compareResult = getAdjustmentCompare(
          originAdjustments,
          parseRateAdjustmentsValidate(
            proposedAdjustments,
            originAdjustments.some(
              ({ description }) => description === "L.O. Compensation"
            )
          )
        );
        dispatch(setCompareDataAction(compareResult));
      }
      dispatch(closeLoader());
    } else {
      dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
    }
  } catch {
    dispatch(closeLoader());
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  }
};

export const getBuysideDataAction = () => async (dispatch, state) => {
  const loanData = get(state(), "EPPS.loanInfo", {});
  const lockRequests = get(
    state(),
    "EPPS.originalLoanInformation.lockRequests",
    []
  );

  const snapshotProgramName = getSnapshotProgramInfo(lockRequests);
  const { relockRequest, loanInformation, additionalLockInfo } = loanData || {};
  const { target } = loanInformation || {};
  const { specificRateDataID, specificProgramID } = relockRequest || {};
  const rate = target <= 20 ? target : null;
  const loanFieldComparisson = Object.values(
    additionalLockInfo?.lockCompareResults || {}
  ).flat();
  const rateChange = loanFieldComparisson.find(({ id }) => id === "rate");
  const { proposed: proposedRate } = rateChange || {};
  const changedNoteRate = !!proposedRate;
  try {
    dispatch(openLoader("Loading"));
    const historicalPricing = await getBuySideQualification(
      "historical",
      loanData,
      dispatch
    );

    const {
      program: historicalProgram,
      status: historicalStatus,
    } = checkPricingStatus(
      specificProgramID,
      specificRateDataID,
      proposedRate || rate,
      historicalPricing,
      changedNoteRate
    );
    dispatch(
      setLockedProgramData({
        programName: historicalProgram?.program || snapshotProgramName,
        programID: specificProgramID,
        notes: historicalProgram?.notes?.replace(/\{.*?\}/g, "") || "",
        status: historicalStatus,
        modalOpen: true,
      })
    );

    if (historicalStatus === "eligible") {
      dispatch(selectProduct(historicalProgram));
      const historicalSummary = DEBUG_BUYSIDE
        ? rateSelectorBuySide
        : await getRateSelector(
            dispatch,
            loanData,
            state()?.EPPS?.originalLoanInformation?.rateLock?.buySideAdjustments
          );
      dispatch(setRateSelectorAction(historicalSummary));

      const { price: historicalPrice, qualifiedRate: originalRate } =
        historicalSummary?.lien || {};

      const {
        program: currentProgram,
        rateDataID: newRateDataID,
        status: currentStatus,
      } = checkPricingStatus(
        specificProgramID,
        specificRateDataID,
        Number(originalRate),
        historicalPricing,
        false
      );

      if (currentStatus === "eligible") {
        const { price: currentPrice } =
          currentProgram?.rateDetails?.find(
            ({ rateDataID }) => rateDataID === newRateDataID
          ) || {};
        const priceDifference =
          typeof currentPrice === "number" &&
          historicalPrice.toFixed(3) !== (100 - currentPrice).toFixed(3);
        if (priceDifference) {
          const newLoanData = {
            ...loanData,
            relockRequest: {
              ...relockRequest,
              effectiveDate: "",
              specificRateDataID: newRateDataID,
            },
          };
          const currentSummary = await getRateSelector(dispatch, newLoanData);
          dispatch(setCurrentRateSelectorAction(currentSummary));
          const compareResult = getAdjustmentCompare(
            historicalSummary?.lien?.adjustments,
            currentSummary?.lien?.adjustments
          );
          dispatch(setCompareDataAction(compareResult));
        }
      }
      dispatch(closeLoader());
    } else {
      dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("getBuysideDataAction.error", err);
    dispatch(closeLoader());
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  }
};

export const getSettingAction = () => async (dispatch) => {
  try {
    dispatch(openLoader("Loading"));
    const settings = await getEncompassSettings();
    if (settings && !settings.code)
      dispatch(setSettings(settings.adjustmentsSettings));
  } catch {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const saveLoanAction = (rateLockContract) => async (dispatch, state) => {
  dispatch(openLoader("Saving Encompass Elements"));
  try {
    const getSummarydetails = get(state(), "epps.lockSummary.rateSelector", {});
    const getEncompassElements = get(
      state(),
      "eppsData.lookups.data.encompassElements",
      []
    );
    const originalLoanInfo = get(state(), "epps.origin.originalLoanData", {});
    const validSummary = !!getEncompassElements.find(
      (element) => element.key === "SummaryQuote"
    );
    const summaryDetails = validSummary ? getSummarydetails : {};
    const getCustomFields = get(
      state(),
      "EPPS.loanInfo.lockRequestAdditionalFields",
      []
    );
    const getOriginSource = Session.get(ORIGIN_SOURCE) || "";
    const customFields = [];

    if (!isEmpty(getCustomFields)) {
      getCustomFields.map((item) => {
        return customFields.push({
          fieldId: item.fieldId,
          value: item.value,
        });
      });
    }

    const transactionId = await Common.createTransaction({
      request: {
        type: "SUBMIT_LOAN",
        options: {
          actionType: "SubmitRates",
          summaryDetails,
          loanElements: [],
          customFields,
          sourceApplicationForm: getOriginSource,
        },
      },
    });

    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === "completed") {
        await Common.refreshGetOrigin();
        const diffELents = await Common.getUpdatedfield({
          loan: { ...originalLoanInfo },
          rateLockInput: {
            lenderFeeWaiverOption: rateLockContract.lenderFeeWaiver,
            noClosingCostOption: rateLockContract.noClosingCost,
          },
          sourceApplicationForm: getOriginSource,
        });
        if (!isEmpty(diffELents)) {
          dispatch(setDiffElementsAction(diffELents));
        } else Common.closeApplication();
      } else if (
        getEpcTransaction.summary === "Conflict" ||
        getEpcTransaction.summary === "Bad Request"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          })
        );
      } else if (
        getEpcTransaction.status === "failed" &&
        getEpcTransaction.code === "EBS-912"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          })
        );
      } else if (["dev", "qa", "int"].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: "Transaction Failed",
          })
        );
      }
    } else throw new Error();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const floatPricingAction = (rateLockInput) => async (
  dispatch,
  state
) => {
  dispatch(openLoader("Floating Pricing"));
  try {
    const summaryDetails = get(state(), "epps.lockSummary.rateSelector", {});
    const originalLoanInfo = get(state(), "epps.origin.originalLoanData", {});
    const getCustomFields = get(
      state(),
      "EPPS.loanInfo.lockRequestAdditionalFields",
      []
    );

    const pricingOriginSource = Session.get(ORIGIN_SOURCE) || "";
    const customFields = [];

    if (!isEmpty(getCustomFields)) {
      getCustomFields.map((item) => {
        return customFields.push({
          fieldId: item.fieldId,
          value: item.value,
        });
      });
    }

    const options = buildEpcLoanObject({
      summaryDetails,
      pricingOriginSource,
    });

    const transactionId = await Common.createTransaction({
      request: {
        type: "FLOAT",
        options: {
          actionType: "SubmitRates",
          sourceApplicationForm: pricingOriginSource,
          loan: options?.loan,
          summaryDetails,
          customFields,
          loanElements: [],
        },
      },
    });

    if (transactionId) {
      await updatePollTransaction({
        transactionID: transactionId,
        options,
      });

      await Common.refreshGetOrigin(true);
      const diffELents = await Common.getUpdatedfield({
        loan: { ...originalLoanInfo },
        rateLockInput,
        sourceApplicationForm: pricingOriginSource,
      });
      if (!isEmpty(diffELents)) {
        dispatch(setDiffElementsAction(diffELents));
      }
      Common.closeApplication();
    } else throw new Error("Missing transaction ID");
  } catch (e) {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const requestLockAction = (
  EPCActionType,
  actionType,
  rateLockContract
) => async (dispatch, state) => {
  dispatch(openLoader("Requesting Lock"));
  try {
    const summaryDetails = get(state(), "epps.lockSummary.rateSelector", {});
    const sellSideBestPrice =
      summaryDetails?.loan?.autolocked?.sellSideBestPrice;
    const originalLoanInfo = get(state(), "epps.origin.originalLoanData", {});
    const getCustomFields = get(
      state(),
      "EPPS.loanInfo.lockRequestAdditionalFields",
      []
    );
    const isLockRequestUnderOnrp = get(
      state(),
      "lockDeskStatus.isLockRequestUnderOnrp",
      {}
    );
    const onrpFields = get(state(), "EPPS.loanInfo.loanInformation", []);
    const { loanChannel, firstMortgageAmount } = onrpFields;
    const getOriginSource = Session.get(ORIGIN_SOURCE) || "";
    const customFields = [];

    if (!isEmpty(getCustomFields)) {
      getCustomFields.map((item) => {
        return customFields.push({
          fieldId: item.fieldId,
          value: item.value,
        });
      });
    }

    let sellSideBestSummary;
    if (sellSideBestPrice) {
      const { loanPrograms, loanId } = get(state(), "EPPS.loanPrograms", {});
      const loanData = get(state(), "EPPS.loanInfo", {});
      const { relockRequest } = loanData;
      const {
        rate: selectedRate,
        loanTerm: selectedTerm,
      } = summaryDetails?.lien;

      const { bestProgramID, bestRateID } = findBestProgram(
        loanPrograms,
        selectedRate,
        selectedTerm
      );
      Session.set(ALL_SUMMARY_PARAMS, {
        loanId,
        programId: bestProgramID,
        rateDataId: bestRateID,
        effectiveDate: relockRequest?.effectiveDate,
      });
      sellSideBestSummary = await getRateSelector(loanData, dispatch);
    }

    const transactionId = await Common.createTransaction({
      request: {
        type: EPCActionType,
        options: {
          actionType,
          summaryDetails,
          customFields,
          loanElements: [],
          sourceApplicationForm: getOriginSource,
          ...(sellSideBestSummary
            ? { sellSideDetails: sellSideBestSummary }
            : {}),
        },
      },
    });

    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === "completed") {
        if (isLockRequestUnderOnrp) {
          await Common.getOnrpAmount(
            loanChannel === 0 ? 1 : loanChannel,
            firstMortgageAmount
          );
        }
        await Common.refreshGetOrigin();
        const diffELents = await Common.getUpdatedfield({
          loan: { ...originalLoanInfo },
          rateLockInput: {
            lenderFeeWaiverOption: rateLockContract.lenderFeeWaiver,
            noClosingCostOption: rateLockContract.noClosingCost,
          },
          sourceApplicationForm: getOriginSource,
        });
        if (!isEmpty(diffELents)) {
          dispatch(setDiffElementsAction(diffELents));
        } else Common.closeApplication();
      } else if (
        getEpcTransaction.summary === "Conflict" ||
        getEpcTransaction.summary === "Bad Request"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          })
        );
      } else if (
        getEpcTransaction.status === "failed" &&
        getEpcTransaction.code === "EBS-912"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          })
        );
      } else if (["dev", "qa", "int"].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: "Transaction Failed",
          })
        );
      }
    } else throw new Error();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const selectPriceAction = (newSelection) => {
  return createAction(SELECT_PRICE)(newSelection);
};

const buildEpcLoanObject = (opts) => {
  const { summaryDetails, pricingOriginSource } = opts;
  const userRoles = Common.getUserRoles();
  const isFromSecondaryLock =
    pricingOriginSource.toLowerCase() === "secondarylock";
  const loanPurposeType = getLoanPurposeType(
    summaryDetails?.loan?.purposeId,
    summaryDetails?.loan?.gseRefinancePurposeType
  );

  const { baseRate, basePrice, baseMarginRate, srp } = getBasePricingValues(
    summaryDetails,
    isFromSecondaryLock
  );

  const { requestPrepayPenalty, requestPenaltyTerm } = getPrepaymentPenaltyTerm(
    summaryDetails
  );

  const propertyType = getPropertyType(summaryDetails);
  const {
    subjectPropertyCondotelIndicator,
    subjectPropertyNonWarrantableProjectIndicator,
  } = getIsCondotel(summaryDetails, propertyType);

  const mortgageType = summaryDetails?.loan?.mortgageType;
  const qualificationTypeId = summaryDetails?.loan?.qualificationTypeId;

  const {
    requestMarginRate,
    requestPriceRateRequested,
  } = getCalculatedAdjustmentValues(summaryDetails);

  const commision = summaryDetails?.lien?.commission;

  const epcLoan = {
    loan: {
      basePrice,
      baseRate,
      baseLoanAmount: summaryDetails?.loan?.baseLoanAmount,
      baseMarginRate,
      borrowerRequestedLoanAmount: summaryDetails?.loan?.baseLoanAmount,
      correspondentCommitmentType: summaryDetails?.loan?.commitmentType,
      correspondentDeliveryType: summaryDetails?.loan?.deliveryType,
      closingCost: {
        gfe2010: {
          gfe2010Fees: [
            {
              gfe2010FeeParentType: "Section800LOCompensation",
              gfe2010FeeType: "LenderCompensationCredit",
              gfe2010FeeIndex: 2,
              rate: commision >= 0 ? Math.abs(commision) : 0,
            },
            {
              gfe2010FeeParentType: "Section800LOCompensation",
              gfe2010FeeType: "DiscountPoint",
              gfe2010FeeIndex: 1,
              rate: commision >= 0 ? 0 : Math.abs(commision),
            },
          ],
        },
      },
      firstSubordinateLienAmount:
        summaryDetails?.lien?.firstSubordinateLienAmount,

      helocTeaserRate: summaryDetails?.lien?.introRate?.toString(),
      loanProductData: {
        armDisclosureType: summaryDetails?.lien?.armIndex
          ? summaryDetails?.lien?.maventIndexCode?.toString()
          : null,
        balloonLoanMaturityTermMonthsCount:
          summaryDetails?.lien?.loanTermMonths ?? 0,
        indexCurrentValuePercent: summaryDetails?.lien?.armIndexValue,
        indexDate: summaryDetails?.lien?.indexRetrievedDate
          ? new Date(summaryDetails?.lien?.indexRetrievedDate)
              .toISOString()
              .split("T")[0]
          : null,
        indexMarginPercent: summaryDetails?.lien?.armMargin,
        lienPriorityType:
          qualificationTypeId === 2 ? "SecondLien" : "FirstLien",
        subsequentRateAdjustmentMonthsCount: summaryDetails?.lien?.armFixedTerm,
        rateAdjustmentDurationMonthsCount: summaryDetails?.lien?.armAdjPeriod,
        rateAdjustmentPercent: summaryDetails?.lien?.armFirstCap,
        rateAdjustmentSubsequentCapPercent:
          summaryDetails?.lien?.armPeriodicCap,
        rateAdjustmentLifetimeCapPercent: summaryDetails?.lien?.armLifetimeCap,
      },
      lockdays: summaryDetails?.lien?.userLockDays,
      loanAmortizationTermMonths: summaryDetails?.loan?.amortizationTermMonths,
      loanAmortizationType: summaryDetails?.loan?.amortizationType,
      loanProgramName: summaryDetails?.lien?.userProgramName,
      mortgageType,
      noClosingCostOption: summaryDetails?.loan?.noClosingCost,
      property: {
        financedNumberOfUnits: getFinancedNumberOfUnits(summaryDetails),
        loanPurposeType,
      },
      propertyAppraisedValueAmount:
        summaryDetails?.loan?.property?.appraisedValueAmount,
      propertyEstimatedValueAmount:
        summaryDetails?.loan?.property?.estimatedValueAmount,
      purchasePriceAmount: summaryDetails?.loan?.property?.purchasePriceAmount,
      rateLock: {
        borrowerRequestedLoanAmount: summaryDetails?.loan?.baseLoanAmount,
        baseLoanAmount: summaryDetails?.loan?.baseLoanAmount,
        borrLenderPaid: getCompensationModel(
          summaryDetails?.loan?.compensationModel
        ),
        buySidePriceRate: isFromSecondaryLock
          ? summaryDetails?.lien?.price
          : null,
        firstTimeHomebuyersIndicator:
          summaryDetails?.loan?.borrowers?.[0]?.finance?.firstTimeHomeBuyers,
        floatDateUpdatedOn: new Date().toLocaleString("en-US", {
          timeZone: "America/Los_Angeles",
        }),
        initialAdvanceAmount: getInitialAdvAmount(
          mortgageType,
          qualificationTypeId
        ),
        planCode: summaryDetails?.lien?.programCode,
        pricingUpdated: new Date().toLocaleString("en-US", {
          timeZone: "America/Los_Angeles",
        }),
        propertyUsageType: getPropertyUsageType(summaryDetails),
        lenderFeeWaiverOption: summaryDetails?.loan?.lenderFeeWaiver,
        loanDocumentationType: getDocumentationType(summaryDetails),
        lockRequestAdjustments: getEpcRateLockAdjustments(
          summaryDetails,
          userRoles
        ),
        requestImpoundWaived: summaryDetails?.lien?.waiveEscrow
          ? "Waived"
          : "Not Waived",
        requestMarginRate,
        requestPenaltyTerm,
        requestPrepayPenalty,
        requestPriceRate: basePrice,
        requestPriceRateRequested,
        requestProgramNotes: summaryDetails?.lien?.note?.join(","),
        requestRate: baseRate,
        requestRateSheetId: isFromSecondaryLock
          ? null
          : summaryDetails?.lien?.rateSheetId,
        requestStartingAdjPoint:
          100 - summaryDetails?.lien?.startingAdjustedPrice,
        requestStartingAdjRate: isFromSecondaryLock
          ? null
          : summaryDetails?.lien?.startingAdjustedRate,
        requestUnDiscountedRate: summaryDetails?.lien?.undiscountedRate,
        secondSubordinateAmount: summaryDetails?.lien?.secondSubordinateAmount,
        subjectPropertyCondotelIndicator,
        subjectPropertyNonWarrantableProjectIndicator,
      },
      requestedInterestRatePercent: summaryDetails?.lien?.qualifiedRate,
      regulationZ: {
        lenderPaidMortgageInsuranceIndicator: summaryDetails?.loan?.lenderPaidMI?.toString(),
      },
      srp,
      twelveMonthMortgageRentalHistoryIndicator:
        summaryDetails?.loan?.borrowers?.[0]?.finance
          ?.demonstrateHousingPaymentHistory,
      unDiscountedRate: summaryDetails?.lien?.undiscountedRate,
    },
  };

  return epcLoan;
};

const getCalculatedAdjustmentValues = (summaryDetails) => {
  const baseAdjustment = summaryDetails?.lien?.adjustments?.find(
    (adj) => adj.adjustmentType === "Base"
  );

  const finalAdjustment = summaryDetails?.lien?.adjustments?.find(
    (adj) => adj.adjustmentType === "Final"
  );

  let requestMarginRate = null;
  if (summaryDetails?.lien?.arm) {
    requestMarginRate = baseAdjustment?.margin;
  }

  return {
    requestMarginRate,
    requestPriceRateRequested: finalAdjustment?.price,
  };
};

const getCompensationModel = (compModelEnum) => {
  const compConfig = {
    1: "Borrower Paid",
    2: "Lender Paid",
    3: "Exempt Paid",
  };

  return compConfig?.[compModelEnum];
};

const getDocumentationType = (summaryDetails) => {
  const docId = summaryDetails?.lien?.documentationId;

  const docIdConfig = {
    1: "FullDocumentation",
    2: "NoVerificationOfStatedIncome",
    3: "NoVerificationOfStatedIncomeOrAssets",
    4: "NoRatio",
    5: "NoDepositVerification",
    7: "NoDocumentation",
    9: "Reduced",
    10: "LimitedDocumentation",
    11: "NoVerificationOfStatedAssets",
  };

  return docIdConfig[docId ?? 1]; // default is full documentation
};

const getFinancedNumberOfUnits = (summaryDetails) => {
  const propTypeId = summaryDetails?.loan?.property?.typeId ?? 1;

  return (propTypeId <= 4 ? propTypeId : 1)?.toString();
};

const getInitialAdvAmount = (
  summaryDetails,
  mortgageType,
  qualificationTypeId
) => {
  if (
    mortgageType === "HELOC" &&
    (qualificationTypeId === 1 || qualificationTypeId === 2)
  ) {
    return summaryDetails?.loan?.initialAdvanceAmount;
  }

  return null;
};

const getLoanPurposeType = (purposeId, gseRefinancePurposeType) => {
  const cashOutRefiSubTypes = [
    "CashOutDebtConsolidation",
    "CashOutHomeImprovement",
    "CashOutLimited",
    "CashOutOther",
  ];

  const noCashOutRefiSubTypes = [
    "NoCashOutFHAStreamlinedRefinance",
    "NoCashOutFREOwnedRefinance",
    "NoCashOutOther",
    "NoCashOutStreamlinedRefinance",
    "ChangeInRateTerm",
  ];

  let purposeType;

  switch (purposeId) {
    case 1:
      purposeType = "Purchase";
      break;
    case 2:
    case 3:
      if (cashOutRefiSubTypes.includes(gseRefinancePurposeType)) {
        purposeType = "Cash-Out Refinance";
      } else if (noCashOutRefiSubTypes.includes(gseRefinancePurposeType)) {
        purposeType = "NoCash-Out Refinance";
      }

      break;
    case 4:
      purposeType = "ConstructionOnly";
      break;
    case 5:
      purposeType = "ConstructionToPermanent";
      break;
    case 6:
      purposeType = "Other";
      break;
    default:
      purposeType = null;
      break;
  }

  return purposeType;
};

const getIsCondotel = (summaryDetails, propertyType) => {
  const propTypeWhitelist = [
    "Condominium",
    "HighRiseCondominium",
    "DetachedCondo",
  ];

  let subjectPropertyCondotelIndicator = false;
  let subjectPropertyNonWarrantableProjectIndicator = false;

  if (propTypeWhitelist.includes(propertyType)) {
    subjectPropertyCondotelIndicator = summaryDetails?.lien?.condotel;
    subjectPropertyNonWarrantableProjectIndicator =
      summaryDetails?.lien?.non_warrantable_condo;
  }

  return {
    subjectPropertyCondotelIndicator,
    subjectPropertyNonWarrantableProjectIndicator,
  };
};

const getPropertyType = (summaryDetails) => {
  const propTypeId = summaryDetails?.loan?.property?.typeId ?? 1;

  const propTypeEnumConfig = {
    5: "Condominium",
    6: "Condominium",
    7: "HighRiseCondominium",
    8: "Attached",
    9: "Cooperative",
    10: "ManufacturedHousing",
    11: "ManufacturedHousing",
    12: "DetachedCondo",
    13: "PUD",
    14: "MHAdvandtage",
    16: "MH CHOICEHome",
  };

  return propTypeEnumConfig[propTypeId] ?? "Detached";
};

const getPrepaymentPenaltyTerm = (summaryDetails) => {
  const ppp = toNumber(summaryDetails?.lien?.ppp);

  const ppeEnumConfig = {
    0: "",
    1: "1 Year",
    2: "2 Years",
    3: "3 Years",
    4: "4 Years",
    5: "5 Years",
  };

  const invalidText = "NOT_VALID";
  const requestPenaltyTerm = ppeEnumConfig[ppp] ?? invalidText;
  let requestPrepayPenalty;
  if (requestPenaltyTerm !== invalidText) {
    requestPrepayPenalty = ppp === 0 ? "N" : "Y";
  }

  return { requestPenaltyTerm, requestPrepayPenalty };
};

const getBasePricingValues = (summaryDetails, isFromSecondaryLock) => {
  const parseBase = (key, adjs) =>
    adjs?.reduce((sum, curr) => sum + parseFloat(curr[key]), 0);

  const baseTypeAdjs = summaryDetails?.lien?.adjustments?.filter(
    (adj) => adj.adjustmentType === "Base"
  );
  const baseRate = parseBase("rate", baseTypeAdjs);
  let basePrice = parseBase("price", baseTypeAdjs);
  let baseMargin = parseBase("margin", baseTypeAdjs);
  let srp;

  const srpPriceTotal = parseBase(
    "price",
    summaryDetails?.lien?.adjustments?.filter(
      (adj) => adj.adjustmentType === "SRP"
    )
  );

  if (!isFromSecondaryLock) {
    basePrice += srpPriceTotal;
  } else {
    srp = round(srpPriceTotal, 3);
  }

  if (!baseMargin) {
    baseMargin = summaryDetails?.lien?.armMargin;
  }

  const allHoldbackValues = summaryDetails?.lien?.adjustments
    ?.filter(
      (adj) =>
        adj.adjustmentType === "Holdback" &&
        adj.description !== "L.O. Compensation"
    )
    // eslint-disable-next-line no-return-assign, no-param-reassign
    ?.reduce((sum, curr) => (sum += curr.price), 0);
  basePrice += allHoldbackValues;

  return {
    basePrice: round(basePrice, 3),
    baseRate: round(baseRate, 3),
    baseMargin: round(baseMargin, 3),
    srp,
  };
};

const getPropertyUsageType = (summaryDetails) => {
  const usageId = summaryDetails?.loan?.property?.usageId;
  let type = "PrimaryResidence";

  if (usageId === 3) {
    type = "Investment";
  } else if (usageId === 2) {
    type = "SecondHome";
  }

  return type;
};

const getEpcRateLockAdjustments = (summaryDetails, userRoles) => {
  const { adjustments } = summaryDetails?.lien;
  const userRoleRoles = userRoles.map(({ role }) => role);
  const canViewCompensation =
    userRoleRoles.includes("View Compensation") &&
    !userRoleRoles.includes("View Profit Margin");

  const potentialAdjustmentsToSend = adjustments.filter(
    (adj) =>
      ["Adjustment", "Profit", "Holdback"].includes(adj.adjustmentType) &&
      (!canViewCompensation || adj.description !== "L.0. Compensation")
  );

  const epcAdjustments = [];
  let priceAdjIndex = 0;
  let marginAdjIndex = 0;
  let rateAdjIndex = 0;
  let profitMarginIndex = 0;
  for (let i = 0; i < potentialAdjustmentsToSend?.length; i += 1) {
    const adj = potentialAdjustmentsToSend[i];

    if (adj.adjustmentType === "Adjustment") {
      if (adj.price !== null && adj.price !== undefined) {
        epcAdjustments.push({
          adjustmentType: adj.adjustmentType,
          description: adj.description,
          priceAdjustmentType: "BasePrice",
          priceAdjustmentTypeIndex: (priceAdjIndex += 1),
          rate: adj.price,
          rateLockAdjustmentType: "Request",
        });
      }

      if (adj.rate !== null && adj.rate !== undefined) {
        epcAdjustments.push({
          adjustmentType: adj.adjustmentType,
          description: adj.description,
          priceAdjustmentType: "BaseRate",
          priceAdjustmentTypeIndex: (rateAdjIndex += 1),
          rate: adj.rate,
          rateLockAdjustmentType: "Request",
        });
      }

      if (adj.margin !== null && adj.margin !== undefined) {
        epcAdjustments.push({
          adjustmentType: adj.adjustmentType,
          description: adj.description,
          priceAdjustmentType: "BaseMargin",
          priceAdjustmentTypeIndex: (marginAdjIndex += 1),
          rate: adj.margin,
          rateLockAdjustmentType: "Request",
        });
      }
    } else if (
      adj.adjustmentType === "Profit" &&
      adj.price !== null &&
      adj.price !== undefined
    ) {
      epcAdjustments.push({
        adjustmentType: adj.adjustmentType,
        description: adj.description,
        priceAdjustmentType: "ProfitMargin",
        priceAdjustmentTypeIndex: (profitMarginIndex += 1),
        rate: adj.price,
        rateLockAdjustmentType: "Request",
      });
    } else if (
      adj.adjustmentType === "Holdback" &&
      adj.price !== null &&
      adj.price !== undefined
    ) {
      if (adj.description === "L.O. Compensation") {
        epcAdjustments.push({
          adjustmentType: "Adjustment",
          description: adj.description,
          priceAdjustmentType: "BasePrice",
          priceAdjustmentTypeIndex: (priceAdjIndex += 1),
          rate: adj.price,
          rateLockAdjustmentType: "Request",
        });
      } else {
        epcAdjustments.push({
          adjustmentType: "Adjustment",
          description: adj.description,
          priceAdjustmentType: "ProfitMargin",
          priceAdjustmentTypeIndex: (profitMarginIndex += 1),
          rate:
            adj.rate !== null && adj.rate !== undefined ? adj.rate : adj.price,
          rateLockAdjustmentType: "Request",
        });
      }
    }
  }

  return epcAdjustments;
};
